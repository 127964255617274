var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-content-box"},[(_vm.index==0)?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.index==10)?_c('div',[_vm._m(5),_c('div',{staticClass:"tip-item"},[_vm._v(" 2.请选择室内照明充足的位置，站立或者端坐。 ")]),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e(),(_vm.index==2)?_c('div',[_vm._m(9),_c('div',{staticClass:"tip-item"},[_vm._v(" 2.请选择室内照明充足的位置，站立或者端坐。 ")]),_vm._m(10),_vm._m(11),_vm._m(12)]):_vm._e(),(_vm.index==1)?_c('div',[_vm._m(13),_c('div',{staticClass:"tip-item"},[_vm._v(" 2.请选择室内照明充足的位置，站立或者端坐。 ")]),_vm._m(14),_vm._m(15),_vm._m(16)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 1.如果您既往有近视、远视和散光，"),_c('em',[_vm._v("请您佩戴眼镜检查")]),_vm._v("，结果更准确。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 2.请您选择室内照明充足的位置，站立或者端坐，在您"),_c('em',[_vm._v("正前方2.5米")]),_vm._v("处，垂直放置一面"),_c('em',[_vm._v("镜子")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 3.请将"),_c('em',[_vm._v("屏幕亮度调为最亮")]),_vm._v("，手机"),_c('em',[_vm._v("背对自己放在胸前")]),_vm._v("，手不要遮挡屏幕，此时您平视可以看到"),_c('em',[_vm._v("镜子中的手机屏幕")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 4.开始检查后，"),_c('em',[_vm._v("先检查左眼，后检查右眼")]),_vm._v("，检查中必须轻轻遮挡另一只眼睛。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 5.根据语音提示进行检查，选择您看到的"),_c('em',[_vm._v("字母开口方向，第一种")]),_vm._v("选择方式是用手指"),_c('em',[_vm._v("滑动手机屏幕")]),_vm._v("，例如您看到字母开口向右，就向右滑动屏幕； "),_c('em',[_vm._v("第二种")]),_vm._v("选择方式是点击屏幕下方的方向"),_c('em',[_vm._v("按钮")]),_vm._v("，例如您看到字母开口向右，就点击屏幕下方的向右按钮；如果您看不清，请点击中间的"),_c('em',[_vm._v("橙色按钮跳过")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 1如果您既往有 近视、远视和散光，"),_c('em',[_vm._v("请您佩戴眼镜检查")]),_vm._v("，结果更准确。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 3.请将手机"),_c('em',[_vm._v("屏幕亮度调为最亮")]),_vm._v(",垂直放在眼睛正"),_c('em',[_vm._v("前方1米")]),_vm._v("处，平视手机屏幕。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 4.开始检查后,"),_c('em',[_vm._v("先检查左眼，后检查右眼")]),_vm._v("检查中必须轻轻遮挡另一只眼睛。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 5.根据语音提示进行检查，选择您看到的"),_c('em',[_vm._v("字母开口方向，第一种")]),_vm._v("选择方式是用手指"),_c('em',[_vm._v("滑动手机屏幕")]),_vm._v("，例如您看到字母开口向右，就向右滑动屏幕； "),_c('em',[_vm._v("第二种")]),_vm._v("选择方式是点击屏幕下方的方向"),_c('em',[_vm._v("按钮")]),_vm._v("，例如您看到字母开口向右，就点击屏幕下方的向右按钮；如果您看不清，请点击中间的"),_c('em',[_vm._v("橙色按钮跳过")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 1.如您有老花眼请"),_c('em',[_vm._v("佩戴老花眼镜")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 3.请将手机"),_c('em',[_vm._v("屏幕亮度调为最亮")]),_vm._v("，垂直放在眼睛"),_c('em',[_vm._v("正前方30厘米处")]),_vm._v("，平视手机屏幕。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 4.开始检查后，"),_c('em',[_vm._v("先检查左眼，后检查右眼")]),_vm._v("，检查中必须轻轻遮挡另一只眼睛。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 5.请根据提示，"),_c('em',[_vm._v("点击按钮")]),_vm._v("并用手指"),_c('em',[_vm._v("画出异常区域")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 1.如您有老花眼请"),_c('em',[_vm._v("佩戴老花眼镜")]),_vm._v("。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 3.请将手机"),_c('em',[_vm._v("屏幕亮度调为最亮")]),_vm._v("，垂直放在眼睛"),_c('em',[_vm._v("正前方25厘米")]),_vm._v("处，平视手机屏幕。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 4.开始检查后，"),_c('em',[_vm._v("先检查左眼，后检查右眼")]),_vm._v("，检查中必须轻轻遮挡另一只眼睛。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-item"},[_vm._v(" 5.根据语音提示进行检查，选择您看到的"),_c('em',[_vm._v("字母开口方向，第一种")]),_vm._v("选择方式是用手指"),_c('em',[_vm._v("滑动手机屏幕")]),_vm._v("，例如您看到字母开口向右，就向右滑动屏幕； "),_c('em',[_vm._v("第二种")]),_vm._v("选择方式是点击屏幕下方的方向"),_c('em',[_vm._v("按钮")]),_vm._v("，例如您看到字母开口向右，就点击屏幕下方的向右按钮；如果您看不清，请点击中间的"),_c('em',[_vm._v("橙色按钮跳过")]),_vm._v("。 ")])
}]

export { render, staticRenderFns }