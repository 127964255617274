<template>
  <div class="content-box home-box">
    <!-- <v-Header :isShowTitle="true" :showback="true" tittle='智能视力检测 ' @back='backMin'>
    </v-Header> -->
    <div v-wechat-title="'智能视力检测'"></div>

    <div class='page-content'>
      <!-- 设置 -->
      <div class='align-right set-icon' style="display:flex;justify-content:space-between">
        <div style="margin-left:20px;width: 100%;">
          <van-row @click="selectIphone = !selectIphone" style="display:flex;align-items:center">
            <van-col span="20" style="text-align:left">
              <div
                style="display:flex;justify-content:space-between;align-items: center; padding:5px 10px;font-size: .4rem; border: 1px solid #f2f2f2;">
                <div>
                  {{ myIphone ? myIphone : '选择手机型号' }}
                </div>
                <div>
                  <van-icon name="arrow-down"></van-icon>
                </div>
              </div>
            </van-col>
            <van-col span="4">
              <van-icon name="setting-o" @click="$router.push({ name: 'setting' })" />
            </van-col>
          </van-row>
        </div>

      </div>

      <ul>
        <li v-for='(item, index) in list' :key='index' class='home-item'  @click='handleLink(item, index)'>
          <div class='inner-box' v-if="!item.isHide">
            <img :src="item.img" alt="" style="width: -webkit-fill-available;">
            <div class='align-left inner-words'>
              <p class='item-title'>{{ item.name }}</p>
              <p class='assist-font-color'>{{ item.note1 }}</p>
              <p class='assist-font-color'>{{ item.note2 }}</p>
            </div>
          </div>
        </li>
      </ul>
      <div class='flex flex-align-items flex-justify-content-between bottom-history' v-if="!from"  @click="goHistory">
        <div class='flex flex-align-items'>
          <img src="../assets/imgs/history.png" alt=""><span class='common-ml' style="font-size:.5rem">检查记录</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class='common-mt assist-font-color' style="margin-top:30px; font-size: .5rem;margin-bottom:30px" @click='show = true'>
        <van-icon name="info" /><span class='note-ml'>检查说明</span>
      </div>
      <van-popup v-model="show" round :style="{ width: '80%' }" class='note-pop'>
        <div class='note-pop-content'>
          <div class='content-item'>该检查依据国标GB11533《标准对数视力表》，在北京协和医院眼科的指导下设计开发完成。</div>
          <div class='content-item'>检查中需要依据您的手机屏幕尺寸绘制标准规范的检查视标。</div>
          <div class='content-item'>为了获得准确的检查结果，每项检查必须按照“检查要求”和“指导语音”完成。</div>
          <div class='content-btn' @click='show = false'>
            确认
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showTip" round :style="{ width: '80%' }" class='tip-pop' v-if='model' @close="onClose">
        <div class='tip-pop-content'>
          <div class='content-title'> 
            <audio id="myAudio" hidden="true" controls ref="myAudio" @ended="overAudio">
              <source :src="model.src1" type="audio/mpeg" />
            </audio> {{ model.name }}
            <div v-if="isPlay" class="mountainCust"></div>
          </div>
          <tip-content :index='ins'></tip-content>
          <!-- <video ref="ref_video" :src="model.src" controls="controls" @close="onClose"></video> -->
          <div class='flex bottom-btn' style="margin: 0px; padding: 0px;">
            <!-- <div class='left-btn'>查看演示</div> -->
            <div class='right-btn' style="width:100%;margin: 0px; padding: 0px;font-size:.5rem;border-radius: unset;"
              @click='handleStart(model)'>
              开始检查
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <!-- <van-action-sheet v-model="selectIphone">
      <van-search style="position: absolute;top: 0; left: 0; right: 0" v-model="searchStr" @search="onSearch"
        @input="onSearch"></van-search>
      <div style="height:1.5rem"></div>
      <div v-for="item in actions" :key="item.Id"
        style="text-align: center; line-height: 35px; border-top: 1px solid #f2f2f2;" @click="onSelect(item)">
        {{ item.Name }}
      </div>
      <div style="height:3rem"></div>
    </van-action-sheet> -->


    <van-popup v-model="selectIphone" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择手机型号"
        :options="actions"
        active-color="#3b89e9"
        @close="selectIphone = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>


<script>
import vHeader from '../components/header.vue'
import mixins_commonMethods from '../commonJs/commonMethods.js'
import tipContent from '../components/tipContent.vue'
export default {
  mixins: [mixins_commonMethods],
  data() {
    return {
      cascaderValue:[],
      tittle: '',
      selectIphone: false,
      show: false,
      showTip: false,
      searchStr: '',
      actions: [],
      ins: null,
      model: null,
      platform: null,
      searchStr: null,
      isPlay: true,
      from:null,
      allowApp:[''],
    }
  },
  components: {
    vHeader, tipContent
  },
  computed: {
    list(){
      if(!this.from){
        return [
        {
          name: '视力检查',
          note1: '远视力表检查',
          note2: '距离2.5米，使用镜子反射手机屏幕',
          img: require('../assets/imgs/home-1.png'),
          link: 'farIndex',
          src: '/assets/Amsler/1.0.mp4',
          src1:"/assets/Amsler/audios/1.0.mp3",//require('../assets/1.0.mp3'),
          desc: ``,
        },
        {
          name: '老花视力检查',
          note1: '近视力表检查',
          note2: '距离25厘米，平视手机屏幕',
          src1:'/assets/Amsler/audios/3.0.mp3',
          img: require('../assets/imgs/home-3.png'),
          link: 'naerIndex',
          src: '/assets/Amsler/audios/3.0.mp3',
          desc: ``,
        },
        {
          name: '眼底黄斑检查',
          note1: '阿姆斯勒表检查',
          note2: '距离30厘米，平视手机屏幕',
          src1:'/assets/Amsler/audios/4.0.mp3',
          img: require('../assets/imgs/home-4.png'),
          link: 'maculaIndexStep1',
          src: '/assets/Amsler/audios/4.0.mp3',
          desc: ``,
        }
      ]
      }else{
        return [
        {
          name: '视力检查',
          note1: '远视力表检查',
          note2: '距离2.5米，使用镜子反射手机屏幕',
          img: require('../assets/imgs/home-1.png'),
          link: 'farIndex',
          src: '/assets/Amsler/1.0.mp4',
          src1:"/assets/Amsler/audios/1.0.mp3",//require('../assets/1.0.mp3'),
          desc: ``,
        },
        {
          name: '眼底黄斑检查',
          note1: '阿姆斯勒表检查',
          note2: '距离30厘米，平视手机屏幕',
          src1:'/assets/Amsler/audios/4.0.mp3',
          img: require('../assets/imgs/home-4.png'),
          link: 'maculaIndexStep1',
          src: '/assets/Amsler/audios/4.0.mp3',
          desc: ``,
        }
      ]
      }
      
    },
    myIphone() {
      return this.$store.state.sys_param.Name || '';
    },
  },
  beforeDestroy() {
    document.getElementById('myAudio').pause();
  },
  created() {
    this.platform = this.$route.query.model;
    this.from = this.$route.query.from;
    if(this.platform){
      this.$httpProxy.get('/AnnoApi/api/baseinfo/screen/getinfo', { name: this.platform }).then(res => {
      let obj = res.data.OutputData || {};
      if (Object.keys(obj).length > 0) {
        this.$store.commit('set_sys_param', res.data.OutputData)
      } 
      this.getIphone();
    })
    }
    if (this.$route.query.userid !=undefined && this.$route.query.userid !=null && this.$route.query.userid !="") {
      this.$vlf.setItem('userName', this.$route.query.name);
      this.$vlf.setItem('userId', this.$route.query.userid)
      this.$vlf.setItem('from', this.$route.query.from)
    }else{
      wx.miniProgram.redirectTo({
        url: 'pages/index/index'
      })
    }
  },
  mounted() {
    var video = document.querySelector('audio');
    document.addEventListener('visibilitychange', () => {
      console.log('visibilitychange',document.visibilityState)
      // 当页面隐藏时候
       if (document.visibilityState === 'visible') {
        this.isPlay = false
        // 视频暂停
        // video.play();
      } else {
        // 当页面显示时视频开始播放
        video.pause();
      }
    });
  },
  methods: {
      onFinish(val){
       this.onSelect(val.selectedOptions[1])
      },
     overAudio(e) {
      this.isPlay = false
      // this.$refs.myAudio.load();
      // this.$refs.myAudio.src=this.src
      // this.$refs.myAudio.play();
    },
    onClose() {
      document.getElementById('myAudio').pause();
      // this.$refs.ref_video.pause()
    },
    async goHistory() {
      window.name = 1;
      let userid = await this.$vlf.getItem('userId');
      let userName = await this.$vlf.getItem('userName')
      this.$router.push({ name: 'history', query: { userId: userid, name: userName } })
    },
    backMin() {
      wx.miniProgram.redirectTo({
        url: '/pages/tabBar/tabBar'
      })
    },
    onSearch(e) {
      if (e) {
        this.actions = this.actions.filter(k => k.Name.toLowerCase().indexOf(e.toLowerCase()) != -1)
      } else {
        this.getIphone()
      }

    },
    getIphone() {
      console.log('-------------')
      this.$httpProxy.get('/AnnoApi/api/baseinfo/screen/getinfos').then(res => {
        if (res.data.Status) {
          // this.$store.commit('set_sys_param', res.data.OutputData)
          let t =res.data.OutputData;
          this.actions = Object.keys(t).map(e => ({
          text: e,
          value: e,
          children: t[e].map(k=>({ text: k.Name, value: k.Name, ...k })),
        }))
        // console.log(this.actions)
        } 
        if (!this.myIphone) {
          this.selectIphone = true
        }
      })
    },
    onSelect(e) {
      this.$store.commit('set_sys_param', e)
    },
    handleLink(item, index) {
      if(!this.myIphone){
        this.$message.info('请先选择您的手机型号');
        return;
      }
      this.ins = index;
      this.model = item;
      this.showTip = true;
      this.$nextTick(()=>{
          document.getElementById('myAudio').load();
          document.getElementById('myAudio').play()
      })
    },
    // 开始检查
    async handleStart(item) {
      window.name = 1;
      this.$store.commit('set_left_eye', '')
      this.$store.commit('set_right_eye', '')
      this.$store.commit('set_eye_text', '左眼')
      // 设置值
      // this.$vlf.setItem('test', {leftEye:3.5, rightEye:2.1

      // }).then(v => {
      //     console.log(v);
      // });
      // this.$vlf.getItem('test').then(res=>{
      //   console.log('--------',res.leftEye);
      // })
      // // 迭代
      // this.$vlf.iterate((value, key, num) => {
      //     console.log('9999999',value, key, num);
      // });
      //  {UserId:this.$route.query.userid}
      let userid = await this.$vlf.getItem('userId')
      this.$httpProxy.post('/api/visual/insert', { UserId: userid }).then((res) => {
        if (res.data.Success) {
          this.$router.push(
            {
              name: item.link,
              query: {
                userId:userid,
                visualId: res.data.ResData
              }
            }
          )
          this.showTip = false;
        } else {
          this.$message.warning('出错了')
        }
      })
    }
  },
};
</script>


 
<style lang="less">
@import "../assets/less/index.less";
.mountainCust {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 20px;
  height: 20px;
  background-image: url('../assets/imgs/a.png');
  animation: mountain_key 1s step-start infinite;
  background-repeat: no-repeat;
  background-size:cover;
}
@keyframes mountain_key {
  0% {
      background-position: 0px 0px;
  }

  33.33% {
      background-position: 33.33% 0px;
  }

  66.33% {
      background-position: 66.33%  0px;
  }

  100% {
      background-position: 100% 0px;
  }
}

.home-box {
  .home-item {
    margin: 0 auto;
    .mt(40);
    width: 90%;
  }

  .set-icon {
    margin-right: 5%;
    .fs(40);
    .mt(20);
  }

  .inner-box {
    position: relative;
  }

  .inner-words {
    position: absolute;
    .top(20);
    .left(30);
    .lh(40);
  }

  .item-title {
    color: #000;
    .fs(40);
    .lh(70);
  }

  .bottom-history {
    margin: 0 auto;
    .mt(40);
    width: 86%;
    background: #fff;
    .b-radius(5);
    .padding(20, 20, 20, 20);
  }

  .note-ml {
    .ml(10)
  }

  .content-title {
    position: relative;
    .lh(60);
    color: #000;
    .fs(40);
    .pt(20);
    .pb(30);
    background: #f5f6f8;
  }

  .tip-pop-content {
    .bottom-btn {
      .pt(20);
      background: #f5f6f8;
    }

    .left-btn {
      background: #FF9D30;
      color: #fff;
      .h(100);
      .lh(100);
      width: 49%;
    }

    .right-btn {
      .ml(20);
      background: #0090FF;
      color: #fff;
      .h(100);
      .lh(100);
      width: 49%;
    }
  }

  .note-pop {
    border-radius: 0;
    background: #f5f6f8;

    .content-item {
      .lh(40);
      .mb(20);
      .align-left;
      .margin(30, 30, 30, 30);
      color: #000000;
    }

    .content-btn {
      background: #fff;
      .lh(70);
      .h(70);
      color: #0090FF;
      text-align: center;
    }
  }
}
</style>