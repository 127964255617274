<template>
    <div class='tip-content-box'>
      <div v-if='index==0'>
        <div class='tip-item'>
          1.如果您既往有近视、远视和散光，<em>请您佩戴眼镜检查</em>，结果更准确。
        </div>
        <div class='tip-item'>
          2.请您选择室内照明充足的位置，站立或者端坐，在您<em>正前方2.5米</em>处，垂直放置一面<em>镜子</em>。
        </div>
        <div class='tip-item'>
          3.请将<em>屏幕亮度调为最亮</em>，手机<em>背对自己放在胸前</em>，手不要遮挡屏幕，此时您平视可以看到<em>镜子中的手机屏幕</em>。
        </div>
        <div class='tip-item'>
          4.开始检查后，<em>先检查左眼，后检查右眼</em>，检查中必须轻轻遮挡另一只眼睛。
        </div>
        <div class='tip-item'>
          5.根据语音提示进行检查，选择您看到的<em>字母开口方向，第一种</em>选择方式是用手指<em>滑动手机屏幕</em>，例如您看到字母开口向右，就向右滑动屏幕；
          <em>第二种</em>选择方式是点击屏幕下方的方向<em>按钮</em>，例如您看到字母开口向右，就点击屏幕下方的向右按钮；如果您看不清，请点击中间的<em>橙色按钮跳过</em>。
        </div>
      </div>
      <div v-if='index==10'>
        <div class='tip-item'>
          1如果您既往有 近视、远视和散光，<em>请您佩戴眼镜检查</em>，结果更准确。
        </div>
        <div class='tip-item'>
          2.请选择室内照明充足的位置，站立或者端坐。
        </div>
        <div class='tip-item'>
          3.请将手机<em>屏幕亮度调为最亮</em>,垂直放在眼睛正<em>前方1米</em>处，平视手机屏幕。
        </div>
        <div class='tip-item'>
          4.开始检查后,<em>先检查左眼，后检查右眼</em>检查中必须轻轻遮挡另一只眼睛。
        </div>
        <div class='tip-item'>
          5.根据语音提示进行检查，选择您看到的<em>字母开口方向，第一种</em>选择方式是用手指<em>滑动手机屏幕</em>，例如您看到字母开口向右，就向右滑动屏幕；
          <em>第二种</em>选择方式是点击屏幕下方的方向<em>按钮</em>，例如您看到字母开口向右，就点击屏幕下方的向右按钮；如果您看不清，请点击中间的<em>橙色按钮跳过</em>。
        </div>
      </div>
      <div v-if='index==2'>
        <div class='tip-item'>
          1.如您有老花眼请<em>佩戴老花眼镜</em>。
        </div>
        <div class='tip-item'>
          2.请选择室内照明充足的位置，站立或者端坐。
        </div>
        <div class='tip-item'>
          3.请将手机<em>屏幕亮度调为最亮</em>，垂直放在眼睛<em>正前方30厘米处</em>，平视手机屏幕。
        </div>
         <div class='tip-item'>
          4.开始检查后，<em>先检查左眼，后检查右眼</em>，检查中必须轻轻遮挡另一只眼睛。
        </div>
         <div class='tip-item'>
          5.请根据提示，<em>点击按钮</em>并用手指<em>画出异常区域</em>。
        </div>
      </div>
      <div v-if='index==1'>
        <div class='tip-item'>
          1.如您有老花眼请<em>佩戴老花眼镜</em>。
        </div>
        <div class='tip-item'>
          2.请选择室内照明充足的位置，站立或者端坐。
        </div>
        <div class='tip-item'>
          3.请将手机<em>屏幕亮度调为最亮</em>，垂直放在眼睛<em>正前方25厘米</em>处，平视手机屏幕。
        </div>
        <div class='tip-item'>
          4.开始检查后，<em>先检查左眼，后检查右眼</em>，检查中必须轻轻遮挡另一只眼睛。
        </div>
        <div class='tip-item'>
          5.根据语音提示进行检查，选择您看到的<em>字母开口方向，第一种</em>选择方式是用手指<em>滑动手机屏幕</em>，例如您看到字母开口向右，就向右滑动屏幕；
          <em>第二种</em>选择方式是点击屏幕下方的方向<em>按钮</em>，例如您看到字母开口向右，就点击屏幕下方的向右按钮；如果您看不清，请点击中间的<em>橙色按钮跳过</em>。
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props:{
    index:{
      type:Number,
      default: 0
    }
  },
  created(){
    console.log(this.index)
  },
  methods: {
  },
};
</script>



<style lang="less">
@import "../assets/less/index.less";
.tip-content-box{
  background: #f5f6f8;
  font-size: 0.45rem;
  padding-bottom: 10px;
  em{
    font-style: normal;
    color: #e4393c;
  }
  .tip-item{
    .lh(40);
    .margin(0,30,30,30);
    .mb(20);
    .pt(10);
    .align-left;
    
    color: #000000 ;
  }
  .tip-item:last-child{
    .mb(0);
  }
}
</style>